import { interopDefault } from '~/router/utils'

export default [
  {
    path: '/information/contact/',
    name: '__information_contact',
    redirect: { name: '__information_support' }
  },
  {
    path: '/information/support/',
    name: '__information_support',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/information/support.vue' /* webpackChunkName: "pages-information-support.vue" */
        )
      )
  }
]
