import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__index',
    path: '/',
    component: () =>
      interopDefault(
        import(
          '~/pages/plot/index.vue' /* webpackChunkName: "pages-plot-index.vue" */
        )
      ),
    meta: { wideContainer: true }
  }
]
